@use '../../scss/imports' as *;

$expansion-header-bg-active: #cfe5f2;

.expansion {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 1.875rem;
  text-align: initial;
  width: 100%;

  .expansion-header {
    align-items: center;
    background: #fff;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    transition: 200ms ease-in-out;

    .expansion-header-passes {
      color: rgba(#000, .3);
      display: inline-block;
      font-family: $font-demi;
      font-size: .875rem;
      padding: 0 .75rem;
    }

    .expansion-header-button {
      color: $secondary-text-color;

      &.md-text--theme-primary {
        color: $action-color;
      }
    }

    .remove-button {
      margin: 0 .5rem;
    }

    &.checked {
      background: $expansion-header-bg-active;
    }
  }

  .expansion-content {
    align-items: start;
    background: #f7f7f7;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr 3rem 1fr;
    height: 100%;
    max-height: 6rem;
    overflow: hidden;
    padding: 1.25rem;
  }


  &.expanded {
    .expansion-content {
      max-height: 100%;
    }
  }
}
