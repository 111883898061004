@use '../../scss/imports' as *;

#needs-confirmation {
  margin-top: 2.375rem;

  .top-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;

    label.rmd-label {
      color: $label-color;
      font-family: $font-regular;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .pair {
    margin-top: .625rem;
  }
}
