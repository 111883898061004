@use '../../scss/imports' as *;

.event-card {
  border-radius: $border-radius;
  box-shadow: 0 2px 2px 0 rgba(#000, .25), 0 0 2px 0 rgba(#000, .12);
  margin-bottom: 1.25rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    font-family: $font-demi;
    font-size: 1rem;
    margin: 0;
  }

  p {
    color: $secondary-text-color;
    margin-bottom: .5rem;

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  .event-source {
    text-transform: capitalize;
  }

  .passes {
    bottom: 1.5rem;
    color: $secondary-text-color;
    display: inline-block;
    position: absolute;
    right: 1rem;
  }

  .indicator {
    background-color: rgba(#000, .1);
    border-radius: 50%;
    display: inline-block;
    height: .625rem;
    margin-right: .375rem;
    width: .625rem;

    &.indicator-active {
      background-color: #aed581;
    }
  }
}

.selectable-event-card {
  opacity: 1;
  position: relative;
  transition: opacity 200ms ease-in-out;

  .selectable-event-icon {
    left: -.5rem;
    opacity: 0;
    position: absolute;
    top: -.5rem;
  }

  .event-card {
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    transition: border-color 200ms ease-in-out;
  }

  &.active {
    .selectable-event-icon {
      opacity: 1;
    }

    .event-card {
      border-color: $action-color;
    }
  }

  &.selectable {
    cursor: pointer;
  }

  &.inactive {
    opacity: .5;
  }
}

.selectable-event-card + .selectable-event-card {
  margin-top: 1.25rem;
}
