$white: #fff;
$black: #000;
$red: #f11a1a;
$label-color: #878787;
$body-bg-color: #f3f3f3;
$primary-color: #2d9cdb;
$action-color: #2d9cdb;
$secondary-color: #ababab;
$warning-color: $red;
$warning-text-color: $white;
$text-color: #2d3843;
$secondary-text-color: rgba($black, .5);

/* TEXTURES */
$button-border-radius: .125rem;
$border-radius: .25rem;
$box-shadow: 0 .125rem .25rem rgba($black, .2);

/* FONTS */
$font-path: '../assets/fonts';
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial, sans-serif !default;
$font-light: 'Avenir Next W01 Light', Arial, sans-serif !default;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif !default;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif !default;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif !default;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial, sans-serif !default;

$wrapper: 62.5rem;

$ph-font-path: 'assets/fonts';
$ph-header-background-color: #7b1fa2;
$ph-header-action-button-color: #dadada;

$xs: 30em;  // 480px
$sm: 37.5em;// 600px
$md: 60em;  // 960px
$lg: 80em;  // 1280px
$xl: 90em;   // 1440px
$ipad: 48em; //768px

$custom-typography-styles: (
  letter-spacing: normal,
);
