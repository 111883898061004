@use '../../scss/imports' as *;

#unassociated {
  margin-top: 2.375rem;

  .top-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;

    .details {
      color: $label-color;
      font-family: $font-regular;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .unassociated-content {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 1fr 3rem 1fr;

    .unassociated-label {
      color: $label-color;
      font-family: $font-demi;
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0 auto 2rem;
      text-align: center;
    }
  }
}
