* {
  box-sizing: border-box;
}

html,
body {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

main {
  padding-bottom: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &[disabled] {
    opacity: .8;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $wrapper;
  padding: 0 1rem;
  position: relative;
}

button:disabled {
  background-color: #ccc;
  opacity: .5;
}

.ph-user-avatar {
  align-items: center;
  background-color: #26c6da;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: .875rem;
  height: 2rem;
  justify-content: center;
  width: 2rem;
}

.search-error {
  align-items: center;
  display: grid;
  justify-content: center;

  h2 {
    color: $label-color;
  }

  p {
    font-size: 1rem;
  }
}

.center {
  text-align: center;
}

.center-v {
  align-items: center;
  display: flex;
}
