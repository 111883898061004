@use '../../scss/imports' as *;

#associated {
  margin-top: 2.375rem;

  .top-controls {
    display: block;
    width: 100%;

    label.input-checkbox span {
      color: $label-color;
      font-family: $font-regular;
      font-size: 1.25rem;
      font-weight: 600;
      padding-left: 1.25rem;
    }
  }

  .pair {
    margin-top: .625rem;
  }
}
