@use '../../scss/imports' as *;

//react datepicker variables
$datepicker__background-color: #fff !default;
$datepicker__border-color: transparent !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $primary-color !default;
$datepicker__text-color: rgba(#000, .7) !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius !default;
$datepicker__day-margin: .166rem !default;
$datepicker__font-size: 1rem !default;
$datepicker__font-family: $font-regular !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: .4rem !default;
$datepicker__navigation-size: .45rem !default;
$datepicker__triangle-size: 8px !default;

@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
  border: 0;
  box-shadow: $box-shadow;
}

.react-datepicker__input-container {
  input {
    border: 0;
    display: block;
    height: 1.5rem;
  }
}

.react-datepicker__header {
  .react-datepicker__day-names,
  .react-datepicker__week {
    background-color: #e0e0e0;
    box-shadow: 0 2px 2px rgba(#000, .18);;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: transparent;
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 2.5rem;
  width: 2.5rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $primary-color;
  border-radius: 50%;
  color: #fff;
}

.react-datepicker__navigation {
  top: .5rem;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__day--today,
.react-datepicker__day--selected {
  font-family: $font-demi;
}



.react-datepicker__current-month,
.react-datepicker-time__header {
  line-height: 2rem;
}

.react-datepicker__day--outside-month {
  color: rgba(#000, .6);
}

.react-datepicker-wrapper {
  border-bottom: 1px solid var(--rmd-form-text-border-color);
  padding: .5rem 0;
}

.search-filter {
  padding: 1.25rem;

  .search-form {
    align-items: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }

  .rmd-icon {
    color: #000;
  }

  .form-group {
    align-items: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2.125rem auto;
    position: relative;

    .form-group-section {
      align-items: center;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: auto 4.625rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .input-range-container {
      align-items: center;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: auto 1rem auto;
    }
  }

  button[type=submit] {
    margin-left: auto;
    margin-top: 1.25rem;
  }

  .reset-button {
    background-color: #f9f9f9;
    color: $black;
  }

  .error-message {
    color: $red;
    font-size: .875rem;
  }

  .to {
    color: rgba(#000, .36);
    font-size: 1.125rem;
  }
}
